<template>
  <section :class="[$style.discount, 'container']">
    <div :class="$style.wrapper">
      <div :class="$style.discountBcg">
        <picture>
          <source srcset="/images/discount/discount.webp" media="(max-width: 1023px)">
          <img src="/images/discount/discount.webp" alt=""/>
        </picture>
      </div>
      <div :class="$style.content">
        <div :class="$style.label" v-html="t('home.discount.label')"></div>
        <div :class="$style.title">{{ t('home.discount.title') }}</div>
        <UiButton
          :class="$style.button"
          :to="localePath('/downloads')"
          color="white"
        >
          <template #label>
            {{ t('home.discount.button') }}
          </template>
        </UiButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .discount {
    position: relative;
    padding-bottom: 60px;

    @media (max-width: 1023px) {
      padding-bottom: 30px;
    }

    &Bcg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 24px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wrapper {
    position: relative;
  }

  .content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 60px 50px 64px 53px;
    color: #fff;
    text-align: center;

    @media (max-width: 1023px) {
      padding: 33px 72px 50px 72px;
    }

    @media (max-width: 767px) {
      padding: 25px;
    }
  }

  .label {
    font-size: 36px;
    font-style: normal;
    font-weight: 100;
    line-height: 47px;
    max-width: 800px;

    span {
      font-weight: 800;
    }

    @media (max-width: 1023px) {
      font-size: 26px;
      max-width: 500px;
      line-height: 28px;
    }

    @media (max-width: 425px) {
      font-size: 16px;
      max-width: 500px;
      line-height: 18px;
    }

  }

  .title {
    margin: 25px 0 50px 0;
    color: #FFF;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px;
    max-width: 800px;

    @media (max-width: 1023px) {
      font-size: 38px;
      line-height: 48px;
    }

    @media (max-width: 425px) {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 30px;
    }

  }
</style>